import React from 'react';

import Layout from '../../components/layout';
import Snippet from '../../components/snippet';
import Code from '../../components/code';
import Tab from '../../components/tab';
import Brace from '../../components/brace';
import PageTitle from '../../components/pagetitle';
import ExDocLink from '../../components/exdoclink';
import Warning from '../../components/warning';
import SEO from '../../components/seo';

const IndexPage = () => (
  <Layout>
    <SEO title="Go Stucts Quick Reference" />
    <PageTitle>Go Stucts Quick Reference</PageTitle>
    <p className="lead">How to declare and use golang struct.</p>

    <Snippet title="Declaring a struct">
        type Customer struct <Brace><br />
        <Tab />ID int64<br />
        <Tab />Name string<br />
        </Brace>
    </Snippet>
    <Snippet title="Using a struct">
      c := Customer<Brace>1, "iPax Ltd"</Brace><br />
      fmt.Println(c.Name) // Prints: iPax Ltd<br />
      fmt.Println(c) // Prints: <Brace>1 iPax Ltd</Brace><br />
    </Snippet>
    <Code>
      var cust Customer // Declaring cust as type of Customer<br />
      fmt.Println("This is an empty struct: ", cust)<br />
      cust.ID = 2<br />
      cust.Name = "iPax"<br />
      fmt.Printf("Customer name is %v and id is %v\n", cust.Name, cust.ID)
    </Code>
    <ExDocLink title="fmt package documentation" link="https://golang.org/pkg/fmt/" />

    <Snippet title="Declare and fill a struct">
      var userID int64 = 2<br />
      name := "Diggz Ltd"<br />
      customer := Customer<Brace><br />
      <Tab />ID: userID,<br />
      <Tab />Name: name,<br />
      </Brace><br />
      fmt.Println(customer)
    </Snippet>

    <Snippet title="Struct with struct tag">
    type Task struct <Brace><br />
    <Tab />Name    string `json:"name"`<br />
	  <Tab />Done    bool   `json:"done"`<br />
    </Brace>
    </Snippet>

    <Snippet title="Struct with several struct tags">
    type Task struct <Brace><br />
    <Tab />Name    string `db:"name" json:"name"`<br />
	  <Tab />Done    bool   `db:"done" json:"done"`<br />
    </Brace>
    </Snippet>
    <ExDocLink title="Go lang struct tags" link="https://github.com/golang/go/wiki/Well-known-struct-tags" />
    
    <Snippet title="Declare a slice of structs">
    type TaskList []Task
    </Snippet>

    <Snippet title="Adding an element to a slice of structs">
    var taskList TaskList<br />
	  task := Task<Brace>"Hello", false</Brace><br />
	  taskList = append(taskList, task)<br />
	  fmt.Println(taskList)<br />
    </Snippet>

    <Snippet title="Declare a map of structs">
    type TaskList map[string]Task
    </Snippet>

    <Snippet title="Adding an element to a map of structs">
    var taskList = make(TaskList)<br />
	  task := Task<Brace>"Hello", false</Brace><br />
	  taskList["first"] = task<br />
	  fmt.Println(taskList)<br />
    </Snippet>



    
  </Layout>
)

export default IndexPage