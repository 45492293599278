import React from 'react'

const Code = (props) => (
    <pre>
        <code>
            {props.children}
        </code>
    </pre>
);

export default Code;