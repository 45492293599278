import React from 'react'

const Brace = (props) => (
    <>
        &#123;
            {props.children}
        &#125;
    </>
);

export default Brace;